
<template>
  <v-form ref="form" v-model="valid" lazy-validation class="mainForm">
    <!-- Kategori -->
    <v-row class="justify-center">
      <v-col cols="12" md="6">
        <v-select
          v-model="category"
          :items="categories"
          :rules="[(v) => !!v || 'Item is required']"
          label="Kategori"
          filled
          required
        ></v-select>
      </v-col>
    </v-row>
    <!-- Titel -->
    <div v-if="category">
      <v-card class="pa-6 mb-6">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="title"
              :counter="24"
              :rules="namnRules"
              label="Titel"
              required
            ></v-text-field>
          </v-col>
          <!-- Titel på engelska -->
          <v-col cols="12" md="6">
            <v-text-field
              v-model="title_en"
              :counter="24"
              :rules="enNamnRules"
              label="Titel engelska"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Subtitle -->
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="subtitle"
              :counter="200"
              :rules="subtitleRules"
              label="Undertext"
              required
            ></v-text-field>
          </v-col>
          <!-- subtitle på engelska -->
          <v-col cols="12" md="6">
            <v-text-field
              v-model="subtitle_en"
              :counter="200"
              :rules="enSubtitleRules"
              label="Undertext engelska"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Fritext -->
        <v-row>
          <v-col cols="12" md="6">
            <v-textarea
              v-model="text"
              :counter="1000"
              :rules="textRules"
              label="Text"
              required
            >
              <template v-slot:label>
                <div>Text <small>(huvudtexten)</small></div>
              </template>
            </v-textarea>
          </v-col>
          <!--  Engelsk fritext -->
          <v-col cols="12" md="6">
            <v-textarea
              v-model="text_en"
              :counter="1000"
              :rules="enTextRules"
              label="Text engelska"
            >
              <template v-slot:label>
                <div>Text engelska <small>(huvudtexten)</small></div>
              </template>
            </v-textarea>
          </v-col>
        </v-row>
      </v-card>
      <!-- Bilder -->
      <v-checkbox
        class="mb-1"
        hide-details
        label="Bild?"
        v-model="hasImg"
      ></v-checkbox>
      <v-card v-if="hasImg" class="pa-6">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="image"
              :counter="1000"
              :rules="imageRules"
              label="Bild URL..."
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-file-input
              accept="image/*"
              label="...eller ladda upp bild"
              @change="imageUpload"
            ></v-file-input>
            <div v-if="successAlert">
              {{ successMessage }}
            </div>
            <div v-if="errorAlert">
              {{ errorMessage }}
            </div>
            <div v-html="uploadedImage" align="center"></div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-select
              v-model="bigimg"
              :items="bigimgopts"
              :rules="bigImgRules"
              label="Stor bild?"
              required
            ></v-select>
          </v-col>
        </v-row>
      </v-card>
      <!-- Plats endast relevant för event -->
      <v-card class="pa-6 my-6" v-if="category === 'event'">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="location"
              :counter="24"
              :rules="namnRules"
              label="Plats"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="location_en"
              :counter="24"
              :rules="enNamnRules"
              label="Plats engelska"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <date-time-picker
              v-model="eventDateTime"
              dateLabel="Datum för event"
              timeLabel="Tid för event">
            </date-time-picker>
          </v-col>
        </v-row>
      </v-card>
      <v-checkbox
        class="mb-1"
        hide-details
        label="Formulär?"
        v-model="hasForm"
      ></v-checkbox>
      <v-card v-if="hasForm" class="pa-6 mb-6">
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <date-time-picker
              v-model="formOpenDateTime"
              dateLabel="Form öppningsdatum"
              timeLabel="Form öppningstid"
              nullable
            >
            </date-time-picker>
          </v-col>
          <v-spacer></v-spacer>

          <v-col cols="12" sm="6" md="6">
            <date-time-picker
              v-model="formCloseDateTime"
              dateLabel="Form stängningsdatum"
              timeLabel="Form stängningstid"
              nullable
            >
            </date-time-picker>
          </v-col>
        </v-row>

        <v-row>
          <!-- TOTAL KAOS, PREPARE -->
          <v-col cols="6" md="3">
            <v-text-field
              v-model="form_id"
              :counter="24"
              :rules="namnRules"
              label="Form ID (om på forms.futf.se)"
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field
              v-model="form_link"
              :counter="1000"
              :rules="namnRules"
              label="eller extern form länk"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="pwd"
            :rules="pwdRules"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            label="Lösenord"
            counter
            @click:append="show1 = !show1"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">
        Validera
      </v-btn>

      <v-snackbar v-model="snackbar">
        {{ snackMsg }}

        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <v-btn color="error" class="mr-4" @click="reset"> Starta Om </v-btn>

      <v-btn color="warning" @click="resetValidation">
        Starta Om Validering
      </v-btn>
      <v-row>
        <v-col sm="12" md="6" v-for="lang in ['swe', 'eng']" :key="lang">
          <newscard
            :title="title"
            :title_en="title_en"
            :subtitle="subtitle"
            :subtitle_en="subtitle_en"
            :text="textRendered"
            :text_en="textEnglishRendered"
            :category="category"
            :publishedTime="currentTime"
            :eventtime="category === 'event' ? eventTimeZulu : null"
            :location="location"
            :location_en="location_en"
            :form="form"
            :image="{url: image, url_en: image, bigimg: bigimg == 'Ja'}"
            :language="lang"
          ></newscard>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>
/* eslint-disable */
const axios = require("axios");
var markdown = require('markdown-it')();
import newscard from '@/components/Newscard.vue'
import DateTimePicker from '@/components/DateTimePicker.vue'

export default {
  data: () => ({
    categories: ["event", "application", "news"],
    snackbar: false,
    snackMsg: "",
    valid: true,
    title: "",
    title_en: "",
    subtitle: "",
    subtitle_en: "",
    text: "",
    text_en: "",
    category: null,
    eventtime: "",
    location: "",
    location_en: "",
    hasImg: false,
    image: "",
    imageFile: null,
    eventDateTime: null,
    formOpenDateTime: null,
    formCloseDateTime: null,
    successAlert: false, // related to image
    errorAlert: false, // --"--
    errorMessage: "", // --"--
    successMessage: "", // --"--
    uploadedImage: "", // --"--
    bigimg: "",
    bigimgopts: ["Ja", "Nej"],
    hasForm: false,
    form_id: "",
    form_link: "",
    form_opentime: "",
    form_closetime: "",
    show1: false,
    pwd: "Lösenord",
    namnRules: [
      (v) => !!v || "Måste fyllas i",
      (v) => (v && v.length <= 100) || "Mindre än 100 bokstäver på namnet, tack",
    ],
    textRules: [
      (v) => !!v || "Glöm inte texten",
      (v) =>
        (v && v.length <= 10000) || "Mindre än 10000 bokstäver på texten, tack",
    ],
    subtitleRules: [
      (v) => !!v || "Glöm inte undertexten",
      (v) =>
        (v && v.length <= 200) ||
        "Mindre än 200 bokstäver på undertexten, tack",
    ],
    enNamnRules: [
      (v) => v.length <= 100 || "Mindre än 100 bokstäver på namnet, tack",
    ],
    enTextRules: [
      (v) => v.length <= 10000 || "Mindre än 10000 bokstäver på texten, tack",
    ],
    enSubtitleRules: [
      (v) => v.length <= 200 || "Mindre än 200 bokstäver på undertexten, tack",
    ],
    imageRules: [
      (v) => v.length <= 1000 || "Mindre än 1000 bokstäver på bilden, tack",
    ],
    bigImgRules: [(v) => !!v || "Måste väljas"],
    timeRules: [
      (v) => !!v || "Glöm inte datumet",
      (v) => (v && v.length <= 50) || "Mindre än 50 bokstäver på tiden, tack",
    ],
    pwdRules: [(v) => !!v || "Lösenord krävs"],
  }),
  components: {
    newscard,
    DateTimePicker
  },
  computed: {
    currentTime() {
      return new Date().toISOString()
    },
    form() {
      if (!this.hasForm) {
        return undefined
      }
      let d = { id: this.form_id, opentime: this.formOpenTimeZulu, closetime: this.formCloseTimeZulu }
      console.log(d)
      return d
    },
    textRendered() {
      const rendered = markdown.render(this.text)
      return rendered
    },
    textEnglishRendered() {
      const rendered = markdown.render(this.text_en)
      return rendered
    },
    eventTimeZulu() {
      if (this.category === 'event') {
        return this.toZuluTime(this.eventDateTime)
      }
      return null
    },
    formOpenTimeZulu() {
      return this.toZuluTime(this.formOpenDateTime)
    },
    formCloseTimeZulu() {
      return this.toZuluTime(this.formCloseDateTime)
    },
  },
  methods: {
    toZuluTime(dateTimeString) {
      if (!dateTimeString) {
        return dateTimeString // Null datetime can be returned as is
      }
      return new Date(dateTimeString).toISOString() // Otherwise, convert local (Uppsala) time to zulu time
    },
    imageUpload(event) {
      // console.log(event);
      this.imageFile = event;
      // console.log(this.imageFile.name);
      // event = file
      let formData = new FormData();

      formData.append("file", this.imageFile);
      axios
        .post("https://api.futf.se/api/uploadImage.php", formData, {
          "Content-Type": "multipart/form-data",
        })
        .then((response) => {
          if (response.data.image == "") {
            console.log(response.data.image);
            console.log("failed");
            this.errorAlert = true;
            this.successAlert = false;
            this.errorMessage = response.data.message;
            this.successMessage = "";
            this.uploadedImage = "";
          } else {
            console.log("success");
            console.log(response.data.image);
            this.errorAlert = false;
            this.successAlert = true;
            this.errorMessage = "";
            this.successMessage = response.data.message; // file name

            let image_path = "../../assets/" + response.data.image;
            // console.log(image_path);
            let image_html = "<img src='" + image_path + "'/>";

            this.uploadedImage = image_html;
            this.imageFile = "";

            this.image = response.data.image;
          }
        });
    },
    validate() {
      if (this.$refs.form.validate()) {
        // Vi vill också lägga till när händelsen publicerades
        this.publishedtime =
          new Date().toISOString().substr(0, 10) +
          "T" +
          new Date().toLocaleTimeString("en-US", {
            hour12: false,
            hour: "numeric",
            minute: "numeric",
          }) +
          "Z";

        // Konvertera från sträng till boolean
        if (this.bigimg === "Ja") {
          this.bigimg = true;
        } else {
          this.bigimg = false;
        }
        // Engelska borde vara frivilligt. Så om "...en" är tom, lägg in Svenskan istället :)
        if (this.title_en === "") {
          this.title_en = this.title;
        }
        if (this.subtitle_en === "") {
          this.subtitle_en = this.subtitle;
        }
        if (this.text_en === "") {
          this.text_en = this.text;
        }
        if (this.location_en === "") {
          this.location_en = this.location;
        }

        // Vid fall av en nyhet ska vi ej ha med datum för event, samt inget form eller plats
        if (this.category === "news") {
          this.eventtime = ""
          this.form_id = 0
          this.form_opentime = ""
          this.form_closetime = ""
          this.location = ""
          this.location_en = ""
        }

        // TODO: Lägg till form_link i API:n
        if (this.form_link !== "") {
          this.form_id == this.form_link
        }

        // Detta är det som skickas till API:n
        console.log({
          title: this.title,
          title_en: this.title_en,
          subtitle: this.subtitle,
          subtitle_en: this.subtitle_en,
          text: this.text,
          text_en: this.text_en,
          category: this.category,
          eventtime: this.eventtime,
          publishedtime: this.publishedtime,
          location: this.location,
          location_en: this.location_en,
          image: this.image,
          bigimg: this.bigimg,
          form_id: this.form_id,
          form_opentime: this.form_opentime,
          form_closetime: this.form_closetime,
        });
        axios
          .post("https://api.futf.se/api/event/create.php", {
            title: this.title,
            title_en: this.title_en,
            subtitle: this.subtitle,
            subtitle_en: this.subtitle_en,
            text: this.textRendered,
            text_en: this.textEnglishRendered,
            category: this.category,
            eventtime: this.eventtime,
            publishedtime: this.publishedtime,
            location: this.location,
            location_en: this.location_en,
            image: this.image,
            bigimg: this.bigimg,
            form_id: this.form_id,
            form_opentime: this.form_opentime,
            form_closetime: this.form_closetime,
            pwd: this.pwd,
          })
          .then((response) => {
            console.log(response.data.message);
            // Hantera status från servern
            if (response.data.message === "Wrong password") {
              this.snackMsg = "Fel lösenord";
            } else if (response.data.message === "Event Not Created") {
              this.snackMsg =
                "Händelse kunde ej laddas upp. Kontrollera angiven data i fälten";
            } else if (response.data.message === "Event Created") {
              this.snackMsg = "Händelse tillagd i databasen!";
            }
          })
          .catch((error) => {
            console.log(error);
          });
        // TODO: detta ska vara igång i produktion
        // this.$refs.form.reset();
        this.snackbar = true;
        // Löser problemet med att man måste välja om bigimg
        if (this.bigimg) {
          this.bigimg = "Ja";
        } else {
          this.bigimg = "Nej";
        }
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped>
.mainForm {
  margin: 20px;
  padding: 20px;
}
</style>